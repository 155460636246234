import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
const apiUrl = import.meta.env.VITE_API_URL;

const FrameBrandFilter = () => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const [brands, setBrands] = useState([]);
  const [filteredBrands, setFilteredBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState({});
  const [showAll, setShowAll] = useState(false); // State to track if all brands are shown

  useEffect(() => {
    // Fetch all brands
    axios.get(`${apiUrl}/api/brands`)
      .then(brandResponse => {
        const allBrands = brandResponse.data.reduce((acc, brand) => {
          acc[brand.id] = {
            id: brand.id,
            title: i18n.language === 'ar' ? brand.name_ar : brand.name_en,
          };
          return acc;
        }, {});

        setBrands(allBrands);
        setFilteredBrands(Object.values(allBrands)); // Set all brands as filtered brands
      })
      .catch(error => {
        console.error("Error fetching brands:", error);
      });
  }, [i18n.language]);

  const handleBrandClick = (brandId) => {
    const updatedSelectedBrands = {
      ...selectedBrands,
      [brandId]: !selectedBrands[brandId],
    };
    setSelectedBrands(updatedSelectedBrands);

    // Create a query string for selected brands
    const selectedBrandIds = Object.keys(updatedSelectedBrands).filter(id => updatedSelectedBrands[id]);
    const queryString = selectedBrandIds.length > 0 ? `?brand_id=${selectedBrandIds.join(',')}` : '';
    
    navigate(`/shop/search${queryString}`);
  };

  const toggleShowAll = () => {
    setShowAll(!showAll); // Toggle the showAll state
  };

  return (
    <div className="sidebar-single-area">
      <h3 className="sidebar-single-area__title">{t('Brands')}</h3>
      <ul className="product-categories brands-search">
        {(showAll ? filteredBrands : filteredBrands.slice(0, 8)).map((brand) => (
          <li className="cat-item" key={brand.id}>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id={brand.id}
                checked={selectedBrands[brand.id] || false}
                onChange={() => handleBrandClick(brand.id)}
              />
              <label className="form-check-label" htmlFor={brand.id}>
                {brand.title}
              </label>
            </div>
          </li>
        ))}
      </ul>
      <button className="btn btn-link" onClick={toggleShowAll}>
        {showAll ? t('Show Less') : t('Show All')}
      </button>
    </div>
  );
};

export default FrameBrandFilter;