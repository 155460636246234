import { useEffect } from 'react';

const useGTM = (gtmId) => {
    useEffect(() => {
        if (!gtmId) return;

        // Create a script element
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
        script.async = true;

        // Append the script to the head
        document.head.appendChild(script);

        // Add the noscript tag
        const noscript = document.createElement('noscript');
        noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
        document.body.insertAdjacentElement('afterbegin', noscript);

        // Cleanup on unmount
        return () => {
            document.head.removeChild(script);
            document.body.removeChild(noscript);
        };
    }, [gtmId]);
};

export default useGTM;