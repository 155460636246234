import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { Link, useNavigate,useSearchParams } from 'react-router-dom';
import { FarzaaContext } from '../../context/FarzaaContext';
import { StoreContext } from '../../context/StoreContext';
import { CurrencyContext } from '../../context/CurrencyContext';  //Currency 
import { AuthContext } from '../authentication/AuthContext'; 
import VirtualTryOn from './TryOn'; // Assuming the component is imported
import ProductViewFilter from "./ProductViewFilter";
import { Riple } from "react-loading-indicators";


const ApiUrl = import.meta.env.VITE_API_URL; 

const ProductContainer = ({ shapeId, genderId, brandId }) => {
  const { t, i18n } = useTranslation(); 
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const { selectedCurrency, value } = useContext(CurrencyContext); //Currency 
   const [filteredProducts, setFilteredProducts] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false); // State to manage popup
    const [pagination, setPagination] = useState({});
    const navigate = useNavigate(); // Initialize navigate
    const { token } = useContext(AuthContext); // Get token from AuthContext
    const { addToWishlist, addToCart } = useContext(StoreContext);
    const [searchParams] = useSearchParams();
    const searchQuery = searchParams.get("search");
    const priceMin = searchParams.get("price_min");
    const priceMax = searchParams.get("price_max");
   const [tryOnImage, setTryOnImage] = useState(null); // State to hold the try-on image




    const handleAddToCart = (product) => { 
      if (!token) {
          // If user is not logged in, redirect to login
          navigate('/login');
      } else {
          // If user is logged in, add to cart
          addToCart(product);
      }
  };
  const handleAddToWishlist = (product) => {
    if (!token) {
        // If user is not logged in, redirect to login
        navigate('/login');
    } else {
        // If user is logged in, add to wishlist
        addToWishlist(product);
    }
};

useEffect(() => {
  const fetchProducts = async () => {
    try {
      let dynamicUrl = `${ApiUrl}/api/products/search`; // Base URL for search

      const params = new URLSearchParams();
      if (shapeId) params.append('shape_id', shapeId);
      if (genderId) params.append('gender_id', genderId);
      if (brandId) params.append('brand_id', brandId);
      if (searchQuery) params.append('search', searchQuery);
      if (priceMin) params.append('price_min', priceMin);
      if (priceMax) params.append('price_max', priceMax);

      // Append all parameters to the base URL
      if (params.toString()) {
        dynamicUrl += `?${params.toString()}`;
      }

      const response = await axios.get(dynamicUrl); // Fetch using the constructed URL
      if (response && response.data) {
        const { data, ...paginationData } = response.data;
        setProducts(data);
        setFilteredProducts(data);
        setPagination(paginationData);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
    finally {
      setLoading(false); // End loading
    }
  };

  fetchProducts();

}, [shapeId, genderId, brandId, searchQuery, priceMin, priceMax]);
if (loading) {
  return <Riple color="#5c5555" size="large" text="Loading" textColor="#000000" />;
}
  

  
  const filterAndSortProducts = (query, sortOption) => {
    let updatedProducts = [...products];

    // Filter by name or price
    if (query) {
      updatedProducts = updatedProducts.filter((product) =>
        product.title_en.toLowerCase().includes(query.toLowerCase()) ||
        product.title_ar.toLowerCase().includes(query.toLowerCase()) ||
        product.selling_price.toString().includes(query)
      );
    }

    // Sort products
    if (sortOption === 'name-az') {
      updatedProducts.sort((a, b) => a.title_en.localeCompare(b.title_en));
    } else if (sortOption === 'name-za') {
      updatedProducts.sort((a, b) => b.title_en.localeCompare(a.title_en));
    } else if (sortOption === 'price-low-high') {
      updatedProducts.sort((a, b) => a.selling_price - b.selling_price);
    } else if (sortOption === 'price-high-low') {
      updatedProducts.sort((a, b) => b.selling_price - a.selling_price);
    }

    setFilteredProducts(updatedProducts);
  };

  
  const fetchProductsByPage = async (pageNumber) => {
    try {
      let dynamicUrl = `${ApiUrl}/api/products?page=${pageNumber}`; // Use a new variable for dynamic URL building
  
      const params = new URLSearchParams();
      if (shapeId) params.append('shape_id', shapeId);
      if (genderId) params.append('gender_id', genderId);
      if (brandId) params.append('brand_id', brandId);
  
      if (params.toString()) {
        dynamicUrl = `${ApiUrl}/api/products/search?${params.toString()}&page=${pageNumber}`;
      }
  
      const response = await axios.get(dynamicUrl);
      if (response && response.data) {
        const { data, ...paginationData } = response.data;
        setProducts(data);
        setFilteredProducts(data);
        setPagination(paginationData);
      }
    } catch (error) {
      console.error("Error fetching paginated products:", error);
    }
  };
  
    const { isListView } = useContext(FarzaaContext);

    // Function to open the popup  
    const openPopup = (product) => {
      // Ensure the try_on_image is available on the product
          setTryOnImage(`${ApiUrl}/uploads/${product.try_on_image}`); // Set the try-on image from the API
          setIsPopupOpen(true);
  };
    const closePopup = () => setIsPopupOpen(false);

    return (
      <div className={`fz-inner-products-container ${isListView ? 'list-view-on' : ''}`}>
                    <ProductViewFilter onFilter={filterAndSortProducts} />

      <div className="row justify-content-center">
        {filteredProducts.length === 0 ? (
          <div className='no-product-area'>
            <h3 className='no-product-text'>{t('No Products Available')}</h3>
            <p className='no-product-desc'>{t("We're sorry. We cannot find any matches for your search term.")}</p>
          </div>
        ) : (
          filteredProducts.map((product) => (
            <div className="col-lg-4 col-md-4 col-6 order-lg-1 order-2" key={product.id}>
              <div className="fz-5-single-product">
                <div className="fz-5-single-product-img">
                <img src={`${ApiUrl}/uploads/${product.image}`} alt={product.title_en} />                
                  <div className="fz-5-single-product-actions">
                    <a 
                      role="button" 
                      className="fz-add-to-wishlist"
                      onClick={() => handleAddToWishlist(product)}
                    >
                      <i className="fa-regular fa-heart"></i>
                    </a>
                    <a
                      role="button"
                      className="fz-add-to-cart-btn" 
                      onClick={() => handleAddToCart(product)} // Use the new function here
                    >
                      <i className="fa-regular fa-cart-shopping"></i>
                    </a>  
                              {/* Conditional rendering for the Try-On button */}
          {product.try_on_image && (
            <a
              role="button"
              className="fz-add-to-cart-btn" 
              onClick={() => openPopup(product)} 
              >
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-eyeglasses" viewBox="0 0 16 19">
                <path d="M4 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4m2.625.547a3 3 0 0 0-5.584.953H.5a.5.5 0 0 0 0 1h.541A3 3 0 0 0 7 8a1 1 0 0 1 2 0 3 3 0 0 0 5.959.5h.541a.5.5 0 0 0 0-1h-.541a3 3 0 0 0-5.584-.953A2 2 0 0 0 8 6c-.532 0-1.016.208-1.375.547M14 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0" />
              </svg>
            </a>
          )}

                
                  </div>
                </div>
                <div className="fz-5-single-product-txt">
                  <h3 className="fz-5-single-product-title">
                    <Link to={`/product/${product.slug_en}`}>
                      {i18n.language === 'ar' ? product.title_ar : product.title_en}
                    </Link>
                  </h3>
                  <p className="fz-5-single-product-price">{t(selectedCurrency)} {(product.selling_price * value).toFixed(2)}</p>


                </div>
              </div>
            </div>
          ))
        )}
      </div>
            


{/* Pagination */}
<div className="p-2">
  {pagination.links && (
    <nav aria-label="Pagination">
      <ul className="pagination justify-content-center flex-wrap">
        {pagination.links.map((link, index) => (
          <li key={index} className={`page-item ${link.active ? 'active' : ''}`}>
            <a
              className="page-link"
              href={link.url}
              onClick={(e) => {
                e.preventDefault();
                const pageNumber = link.label === 'Next' ? pagination.current_page + 1 : link.label === 'Previous' ? pagination.current_page - 1 : link.label;
                fetchProductsByPage(pageNumber);
              }}
              style={{
                color: 'black',
                fontSize: '0.9rem', // Adjust font size for mobile
              }}
            >
              {link.label === '&laquo; Previous' ? t('Previous') : link.label === 'Next &raquo;' ? t('Next') : link.label}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  )}
</div>
        {/* Try-On Popup */}
        {isPopupOpen && (
          <div className="try-on-popup">
            <div className="try-on-content">
              <button className="close-popup" onClick={closePopup}>Close</button>               
              <VirtualTryOn tryOnImage={tryOnImage} />  {/* Pass tryOnImage to VirtualTryOn */}


            </div>
          </div>
        )}
      </div>
    );
};

export default ProductContainer;
