import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { StoreContext } from '../../context/StoreContext';
import { AuthContext } from '../authentication/AuthContext';
const apiUrl = import.meta.env.VITE_API_URL;
const ShippingAddressSection = () => {
  const { cart } = useContext(StoreContext);
  const { token } = useContext(AuthContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const shipping_method_id = searchParams.get('shipping_method_id');

  const [t, i18n] = useTranslation();
  const [addresses, setAddresses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newAddress, setNewAddress] = useState({
    address: '',
    country: '',
    state: '',
    city: '',
    street: '',
    appartment: '',
    zip: '',
  });

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/user-addresses`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAddresses(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchAddresses();
  }, [token]);

  const handleAddAddress = async () => {
    if (
     !newAddress.address ||
     !newAddress.country ||
     !newAddress.state ||
     !newAddress.city ||
     !newAddress.street ||
     !newAddress.appartment ||
     !newAddress.zip
    ) {
      alert(t('Please fill in all fields'));
      return;
    }
  
    if (shipping_method_id === '1') {
      // Require specific address fields for shipping method ID 1
      if (!newAddress.country || newAddress.country!== 'Saudi Arabia') {
        alert(t('Please select Saudi Arabia as the country'));
        return;
      }
      if (!newAddress.state || newAddress.state!== 'Riyadh') {
        alert(t('Please select Riyadh as the state'));
        return;
      }
      if (!newAddress.city || newAddress.city!== 'Riyadh') {
        alert(t('Please select Riyadh as the city'));
        return;
      }
    }
  
    try {
      const response = await axios.post(`${apiUrl}/api/add-address`, newAddress, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAddresses([...addresses, response.data]);
      setShowModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (event) => {
    setNewAddress({ ...newAddress, [event.target.name]: event.target.value });
  };

  return (
    <div className="container">
      <div className="fz-checkout">
        <h4 className="text-center p-2">{t('Please choose an address!')}</h4>
        <br />
        <Row className="justify-content-center">
          {addresses.length > 0 ? (
            addresses.map((address, index) => (
              <Col md={4} key={index}>
                <div className="card" style={{ width: '18rem' }}>
                  <div className="card-body">
                    <h5 className="card-title text-center">{address.address}</h5>
                    <p className="card-text text-center">{address.country}</p>
                    <p className="card-text text-center">{address.state}</p>
                    <p className="card-text text-center">{address.city}</p>
                    <p className="card-text text-center">{address.street}</p>
                    <p className="card-text text-center">{address.appartment}</p>
                    <p className="card-text text-center">{address.zip}</p>

                    <Link
                      to={{
                        pathname: '/checkout',
                        search: `?address_id=${address.id}&shipping_method_id=${shipping_method_id}`,
                      }}
                      className="btn btn-dark text-center d-block mx-auto"
                    >
                      {t('select')}
                    </Link>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <Col md={4}>
              <div className="card" style={{ width: '18rem' }}>
                <div className="card-body">
                  <h5 className="card-title text-center">{t('No addresses available')}</h5>
                  <button
                    className="btn btn-dark text-center d-block mx-auto"
                    onClick={() => setShowModal(true)}
                  >
                    {t('Add address')}
                  </button>
                </div>
              </div>
            </Col>
          )}
        </Row>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Add new address')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>{t('Address')}</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  value={newAddress.address}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('Country')}</Form.Label>
                <Form.Control
                  type="text"
                  name="country"
                  value={newAddress.country}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('State')}</Form.Label>
                <Form.Control
                  type="text"
                  name="state"
                  value={newAddress.state}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('City')}</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  value={newAddress.city}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('Street')}</Form.Label>
                <Form.Control
                  type="text"
                  name="street"
                  value={newAddress.street}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('Appartment')}</Form.Label>
                <Form.Control
                  type="text"
                  name="appartment"
                  value={newAddress.appartment}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('Zip')}</Form.Label>
                <Form.Control
                  type="text"
                  name="zip"
                  value={newAddress.zip}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer> 
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              {t('Close')}
            </Button>
            <Button variant="primary" onClick={handleAddAddress}>
              {t('Add address')}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default ShippingAddressSection;
