import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const apiUrl = import.meta.env.VITE_API_URL;


const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(null);
  const [otp, setOtp] = useState(''); // new state to store OTP
  const [otpVerified, setOtpVerified] = useState(false); // new state to track OTP verification

  const SESSION_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds


  
  useEffect(() => {
  //   const storedToken = localStorage.getItem('token');
  //   if (storedToken) {
  //     setToken(storedToken);
  //     setIsLoggedIn(true);
  //   }
  // }, []);
  const storedToken = localStorage.getItem('token');
  const loginTimestamp = localStorage.getItem('loginTimestamp');

  if (storedToken && loginTimestamp) {
    const currentTime = new Date().getTime();
    if (currentTime - loginTimestamp < SESSION_DURATION) {
      setToken(storedToken);
      setIsLoggedIn(true);
    } else {
      // If the session has expired, log the user out
      handleLogout();
    }
  }
}, []);

  useEffect(() => {
    const checkAuthentication = async () => {
      if (token) {
        try {
          const response = await axios.get(`${apiUrl}/api/authenticated`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setIsLoggedIn(response.data.authenticated);
        } catch (error) {
          console.error(error);
        }
      }
    };
    checkAuthentication();
  }, [token]);

  useEffect(() => {
    const checkSession = async () => {
      const isLoggedIn = localStorage.getItem('isLoggedIn');
      if (isLoggedIn) {
        setIsLoggedIn(true);
      }
    };
    checkSession();
  }, []);


  const handleLogin = async (email, password) => {
    try {
      const response = await axios.post(`${apiUrl}/api/login`, {
        email,
        password,
      });
      console.log(response.data);
      if (response.data.success) {
        setToken(response.data.token);
        setIsLoggedIn(true);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('loginTimestamp', new Date().getTime()); // Store the current timestamp

        console.log('token',response.data.token)
        return true;
      } else {
        if (response.data.message === 'Account not activated') {
          toast.error('The account is not activated. Please check your email for OTP. !', { position: 'top-right' });
        } else {
          toast.error('Invalid email or password.', { position: 'top-right' });
        }
        return false;
      }
    } catch (error) {
      console.error(error);
      toast.error('An error occurred. Please try again later.', { position: 'top-right' });
      return false;
    }
  };

  const handleLoginPhone = async (phone) => {
    try {
      const response = await axios.post(`${apiUrl}/api/login-phone`, {
        phone,
      });
      console.log(response.data);
      if (response.data.success) {
        return true;
      } else {
        if (response.data.message === 'Account not activated') {
          toast.error('The account is not activated. Please check your email for OTP. !', { position: 'top-right' });
        } else {
          toast.error('Invalid email or password.', { position: 'top-right' });
        }
        return false;
      }
    } catch (error) {
      console.error(error);
      toast.error('An error occurred. Please try again later.', { position: 'top-right' });
      return false;
    }
  };

  const handleLoginPhoneOtp = async (phone, otp) => {
    try {
        const response = await axios.post(`${apiUrl}/api/login-phone-success`, {
            phone,
            otp,
        });

        console.log(response.data);

        if (response.data.success) {
            // Set the token and update the login state
            setToken(response.data.token);
            setIsLoggedIn(true);
            localStorage.setItem('token', response.data.token);
            console.log('Token:', response.data.token);
            return true;
        } else {
            // Handle different error messages
            if (response.data.message === 'Account not activated') {
                toast.error('The account is not activated. Please check your email for OTP.', { position: 'top-right' });
            } else if (response.data.message === 'Invalid OTP') {
                toast.error('The OTP you entered is invalid. Please try again.', { position: 'top-right' });
            } else {
                toast.error('An error occurred. Please try again.', { position: 'top-right' });
            }
            return false;
        }
    } catch (error) {
        console.error('Error during OTP verification:', error);
        toast.error('An error occurred while verifying the OTP. Please try again later.', { position: 'top-right' });
        return false;
    }
};

  
  
  const handleRegister = async (name, email, password, phone) => {
    try {
      const response = await axios.post(`${apiUrl}/api/add-user`, {
        name,
        phone,
        email,
        password,
      });
  
      if (response.data.success) {
     //   setToken(response.data.token);
        // setIsLoggedIn(true);
        toast.success('Registered successfully. Please check your email for OTP. !', { position: 'top-right' });
        // show OTP input popup
        setOtpVerified(false);

      } else {
        
        toast.error(response.data.message, { position: 'top-right' });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleRegisterPhone = async (phone) => {
    try {
      const response = await axios.post(`${apiUrl}/api/add-user`, {
        phone,
      });
  
      if (response.data.success) {
        toast.success('Registered successfully. Please check your phone for OTP. !', { position: 'top-right' });
        // show OTP input popup
        setOtpVerified(false);

      } else {
        
        toast.error(response.data.message, { position: 'top-right' });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleOtpVerification = async (otpValue) => {
    try {
      const response = await axios.get(`${apiUrl}/api/activate/${otpValue}`);

      if (response.data.success) {
        setOtpVerified(true);
      } else {
        toast.success('OTP number successful!', { position: 'top-right' });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGoogleAuth = (token) => {
    setToken(token);
    localStorage.setItem('token', token);
    setIsLoggedIn(true);
    toast.success('Signed in with Google successfully!', { position: 'top-right' });
};

const handleFacebookAuth = (token) => {
  setToken(token);
  localStorage.setItem('token', token);
  setIsLoggedIn(true);
  toast.success('Signed in with Facebook successfully!', { position: 'top-right' });
};

const handleAppleAuth = (token) => {
  setToken(token);
  localStorage.setItem('token', token);
  setIsLoggedIn(true);
  toast.success('Signed in with Apple successfully!', { position: 'top-right' });
};


      

  const handleLogout = async () => {
    try {
      await axios.post(`${apiUrl}/api/logout`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setToken(null);
      setIsLoggedIn(false);
      
      localStorage.removeItem('token');
      localStorage.removeItem('loginTimestamp'); // Clear the timestamp


    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        token,
        handleLogin,
        handleLoginPhone,
        handleRegister,
        handleRegisterPhone,
        handleLogout,
        setIsLoggedIn,
        handleLoginPhoneOtp,
        setToken,
        otp,
        setOtp,
        otpVerified,
        handleOtpVerification,
        handleGoogleAuth,
        handleFacebookAuth,
        handleAppleAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };