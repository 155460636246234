import React, { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import countries from './countries.json';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Swal from 'sweetalert2';
import { StoreContext } from '../../context/StoreContext';
import { CurrencyContext } from '../../context/CurrencyContext';  //Currency 
import { toast } from 'react-toastify';  
import { AuthContext } from '../authentication/AuthContext';
import TabbyBadge from '../../../public/assets/images/tabby-badge.svg';
import $ from 'jquery';
import { Riple } from "react-loading-indicators";


const apiUrl = import.meta.env.VITE_API_URL;


const CheckoutSection = () => {
  const { t, i18n } = useTranslation(); 
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { products, cart, removeFromCart } = useContext(StoreContext);
  const [subTotal, setSubTotal] = useState(0);
  const [countriesList, setCountriesList] = useState([]);
  const { selectedCurrency, value } = useContext(CurrencyContext); 
  const [shipping, setShipping] = useState(0);
  const [orderId, setOrderId] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [LimitofTabby, setLimitofTabby] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [addressId, setAddressId] = useState(null);
  const [shippingMethodId, setShippingMethodId] = useState(null);

  useEffect(() => {
    // Extract addressId and shippingMethodId from URL params
    const params = new URLSearchParams(window.location.search);
    const addrId = params.get('address_id');
    const shipMethodId = params.get('shipping_method_id');

    if (addrId) setAddressId(addrId);
    if (shipMethodId) setShippingMethodId(shipMethodId);
  }, []);



  const [coupon, setCoupon] = useState({
    value: 0,
    type: '',
  });
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    phone: '',
  });

  const useCoupon = async (couponCode) => {
    try {
      const response = await axios.post(`${apiUrl}/api/use-coupon`, { code: couponCode }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      setCoupon({
    value: data.value,
    type: data.type,
    }); // update coupon value and type
      Swal.fire({
        title: "Success!",
        text: "Coupon applied successfully!",
        icon: "success",
      });
    } catch (error) {
      console.error(error.response.data); // Log the error response
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Invalid coupon code!",
      });
    }
  };
  const calculateDiscountAmount = (subTotal) => {
    let discountAmount = 0;
    if (coupon.value > 0) {
      if (coupon.type === 'percentage') {
        discountAmount = (subTotal * coupon.value) / 100;
      } else if (coupon.type === 'fixed') {
        discountAmount = coupon.value;
      }
    }
    return discountAmount;
  };
  


  useEffect(() => {
    const fetchUserData = async () => {
      if (!token) return; // Early return if token is not available
      try {
        const response = await axios.get(`${apiUrl}/api/user`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = response.data;
        setUserData({
          name: data.name,
          email: data.email,
          phone: data.phone,
        });
        localStorage.setItem('userId', data.id);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        toast.error(t('Failed to fetch user information'));
      }
      finally {
        setLoading(false); // End loading
      }
    };
    
    if (token) {
      fetchUserData();
    }


  },
   [token, t]);
  //  if (loading) {
  //   return <Riple color="#5c5555" size="large" text="Loading" textColor="#000000" />;
  // }

  useEffect(() => {
    const fetchShippingPrice = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/shipping-methods`);
        const shippingData = response.data;
        const shippingPrice = shippingData.find(method => method.id === Number(new URLSearchParams(window.location.search).get('shipping_method_id')));
        if (shippingPrice) {
          setShipping(shippingPrice.price_shipping);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    const shippingMethodId = new URLSearchParams(window.location.search).get('shipping_method_id');
    if (shippingMethodId) {
      fetchShippingPrice();
    }
  }, []);


        // address
  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/addresses`);
        const addressData = response.data;
      } catch (error) {
        console.error(error);
      }
    };
  
    const addressId = new URLSearchParams(window.location.search).get('address_id');

  }, []);


  useEffect(() => {
    setCountriesList(Object.keys(countries));
  }, []);

  useEffect(() => {
    let calculatedTotalPrice = 0;
    setLoading(true); // Start loading for calculations
    cart.forEach((product) => {
      const productDetails = products[product.product_id];
      
      if (productDetails && productDetails.selling_price) {
        calculatedTotalPrice += product.quantity * productDetails.selling_price;
      }
    });
    console.log('Cart:', cart);
    console.log('Products:', products);
    console.log('Calculated Total Price:', calculatedTotalPrice);
    setSubTotal(calculatedTotalPrice);
    setLoading(false);
  }, [cart, products]);



  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  };

  // Tabby Integration start
  const getTabbyCheckoutSession = async () => {
    // Prepare items from the cart
    const items = cart.map((product) => ({
      title: product.title || 'No Title', 
      description: product.description || 'No Description', 
      quantity: product.quantity,
      unit_price: product.price, 
      discount_amount: (product.discount || 0),
      reference_id: product.reference_id || "default_ref_id", 
      image_url: product.image_url || "http://example.com/default.jpg", 
      product_url: product.product_url || "http://example.com", // Default product URL
      gender: product.gender || "Unspecified", // Default gender
      category: product.category || "General", // Default category
      color: product.color || "N/A", // Default color
      product_material: product.material || "N/A", // Default material
      size_type: product.size_type || "N/A", // Default size type
      size: product.size || "N/A", // Default size
      brand: product.brand || "N/A" // Default brand
    }));
  
    const data = {
      payment: {
        amount: total.toString(), // Ensure this is a string
        currency: 'SAR',
        description: 'Your payment description here', // Add a meaningful description
        buyer: {
          phone: userData.phone || '', // Fallback to empty string if phone is not provided
          email: userData.email || '', // Fallback to empty string if email is not provided
          name: userData.name || 'Anonymous', // Default name
          dob: userData.dob || '', // Add date of birth if available
        },
        buyer_history: {
          registered_since: userData.registered_since || new Date().toISOString(), // Default to now if not provided
          loyalty_level: userData.loyalty_level || 0, // Default loyalty level
          wishlist_count: userData.wishlist_count || 0, // Default wishlist count
          is_social_networks_connected: userData.is_social_networks_connected || false,
          is_phone_number_verified: userData.is_phone_number_verified || false,
          is_email_verified: userData.is_email_verified || true,
        },
        order: {
          tax_amount: '0.00', // Add tax amount if applicable
          shipping_amount: '0.00', // Add shipping amount if applicable
          discount_amount: calculateDiscountAmount(), // Ensure this calculates discount dynamically
          updated_at: new Date().toISOString(), // Current date and time
          reference_id: `ref_${Date.now()}`, // Unique reference ID using current timestamp
          items: items,
        },
        order_history: [], // Fill this if you have order history
        shipping_address: {
          city: userData.city || 'Default City',
          address: userData.address || 'Default Address',
          zip: userData.zip || 'Default Zip',
        },
        meta: {
          order_id: '#1234', // Set your order ID here
          customer: '#customer-id', // Set customer ID here
        },
        attachment: {
          body: JSON.stringify({
            flight_reservation_details: {
              pnr: "TR9088999",
              itinerary: [],
              insurance: [], 
              passengers: [], 
              affiliate_name: "some affiliate"
            }
          }),
          content_type: "application/vnd.tabby.v1+json"
        }
      },
      lang: 'ar', // Change as needed
      merchant_code: 'RES', // Use the correct merchant code
      merchant_urls: {
        success: `https://regaleyes.sa/checkout?address_id=${addressId}&shipping_method_id=${shippingMethodId}`,
        cancel: `https://regaleyes.sa/checkout?address_id=${addressId}&shipping_method_id=${shippingMethodId}`,
        failure: `https://regaleyes.sa/checkout?address_id=${addressId}&shipping_method_id=${shippingMethodId}`,
    },
    };

  
    console.log('Request Data:', JSON.stringify(data, null, 2));
    
    try {
      const response = await axios.post(`${apiUrl}/api/tabby/create-checkout`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    
      // Log the full response for debugging
      
      // Extract the session ID and store it for the next step
      const sessionId = response.data.payment.id; // Store the session ID
      let redirect_url;
      if(response.data.configuration.available_products!=""){
       redirect_url= response.data.configuration.available_products.installments[0].web_url
      }
      if(response.data.rejection_reason_code){
        setLimitofTabby(true);
      }
      console.log('API Response:', response.data.rejection_reason_code);
     


      return { sessionId, redirect_url}; // Return the session ID and API URL
    } catch (error) {
      console.error('Error fetching Tabby checkout session:', {
        message: error.message,
        response: error.response ? error.response.data : 'No response',
        requestData: data, // Log the request data for reference
      });
      throw error; // Rethrow the error for further handling
    }
  };
  
  
 

  const postTabbyOrder = async () => {
    if(selectedPaymentMethod===3){
      
      const { sessionId , redirect_url} = await getTabbyCheckoutSession();

      if (sessionId) {
        
         try {
          const response = await axios.post(`${apiUrl}/api/order/store-tabby-order`, {
            
          
            payment_id: sessionId,  // Ensure payment_id is included here
            // other order data
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if(redirect_url){
            window.location.href=redirect_url
          }
          
          
         return response
        } catch (error) {
         return error;
        }
      }
      

    }else{
      try {
        const response = await axios.post(`${apiUrl}/api/order/store-tabby-order`, {
          
        
          payment_id: selectedPaymentMethod,  // Ensure payment_id is included here
          // other order data
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        console.log('Order posted successfully:', response.data);
      } catch (error) {
        console.error('Error posting Tabby order:', error.response.data);
      }

    }
   
  
  };
  

    

  // Tabby Integration end

  const getCartIdFromApi = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/cart`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const cartData = response.data;
      if (Array.isArray(cartData) && cartData.length > 0) {
        return cartData.map(cartItem => cartItem.id); // Return array of cart IDs
      }
    } catch (error) {
      console.error(error);
      // return null;
      return [];
    }
  };

  const total = (subTotal + +shipping - +calculateDiscountAmount(subTotal)).toFixed(2);


 const tabbySnippet = () => {
  console.log(total);
  localStorage.setItem("total",total)
  new TabbyCard({
    selector: '#tabbyCard',
    currency: 'SAR',
    lang: i18n.language,
    price: total,
    size: 'narrow',
    theme: 'black',
    header: false,
  });
};
  
  useEffect(() => {
    if (selectedPaymentMethod === 3) {
      localStorage.setItem('total',total)
      tabbySnippet();
    } 
  }, [selectedPaymentMethod, total]);

  useEffect(() => {
    if (selectedPaymentMethod === 2) {
      // Initialize Moyasar payment
      if (selectedCurrency == 'SAR' || selectedCurrency == 'USD') {

      localStorage.setItem('total',total)
      Moyasar.init({
        element: '.mysr-form',
        amount: Math.round(total * value * 100),
        // amount: total*100, 
        // currency: 'SAR',
        currency: selectedCurrency,
        description: 'Order Payment',
        publishable_api_key: 'pk_live_zVA5WYLavgb9Zb64LxGWN6tM9fTLzCAJYQeKKSpM', // Use .env for security
        callback_url : `https://regaleyes.sa/checkout?address_id=${addressId}&shipping_method_id=${shippingMethodId}`,
      methods: ['creditcard','applepay'],
      apple_pay: {
        country: 'SA',
        label: 'Awesome Cookie Store',
        validate_merchant_url: 'https://api.moyasar.com/v1/applepay/initiate',
    },
  });
} else {
  toast.error(t('Selected currency is not supported. Please choose SAR or USD.'));
}
      
  
      handleSubmit(new Event('submit'));
    }
  }, [selectedPaymentMethod, total, apiUrl,addressId,shippingMethodId,selectedCurrency]);




  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Start loading
    const userId = localStorage.getItem('userId');
    const cartIds = await getCartIdFromApi();
    const shippingMethodId = new URLSearchParams(window.location.search).get('shipping_method_id');
    const addressId = new URLSearchParams(window.location.search).get('address_id');
    localStorage.setItem("addressId", addressId);

    const paymentMethodId = selectedPaymentMethod;
    const notes = event.target.notes.value;

    const productsData = cart.map((product) => ({
      product_id: product.product_id,
      quantity: product.quantity,
    }));

    let calculatedTotalPrice = 0;
    cart.forEach((product) => {
      const productDetails = products[product.product_id];
      if (productDetails && productDetails.selling_price) {
        calculatedTotalPrice += product.quantity * productDetails.selling_price;
      }
    });

    const currentYear = new Date().getFullYear();
    const lastTwoDigitsOfYear = currentYear.toString().slice(-2);
    const randomNumbers = Math.floor(1000 + Math.random() * 9000);
    const orderNo = `0e-${lastTwoDigitsOfYear}-${randomNumbers}`;

    const data = {
      user_id: userId,
      cart_id: cartIds,
      shipping_method_id: shippingMethodId,
      address_id: addressId,
      payment_method_id: paymentMethodId,
      products: productsData,
      order_no: orderNo,
      total_price: calculatedTotalPrice,
      status: paymentMethodId === 1 ? '0' : '1', // Set status to '0' for cash on delivery
      notes: notes,
    };

    console.log('Order Data:', data); // Log the data before sending


    try {
      if (selectedPaymentMethod === 3) { 
        const { response } = await postTabbyOrder();
        if (response && response.data) {
          setOrderId(response.data.combined_order_id);
        }
    } else if(selectedPaymentMethod === 2){
      const response = await axios.post(`https://app.regaleyes.sa/api/add-order`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response && response.data) {
        setOrderId(response.data.combined_order_id);
      } 
    }

    else {
      const response = await axios.post(`${apiUrl}/api/add-order`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response && response.data) {
        setOrderId(response.data.combined_order_id);
        Swal.fire({
          title: "Success!",
          text: "Your order is successfully placed!",
          icon: "success",
        });
        for (const product of cart) {
          if (product) { // Ensure product is defined
            await removeFromCart(product); // Use await to ensure each removal is completed
          }
        }
        // if (loading) {
        //   return <Riple color="#5c5555" size="large" text="Loading" textColor="#000000" />;
        // }
        navigate('/thank-you');
      }
    }
  } catch (error) {
    console.error('Error posting order:', error.response ? error.response.data : error);
    if (LimitofTabby) {
      Swal.fire({
        icon: "error",
        title: i18n.language === "ar" ? "عفواً..." : "Oops...",
        text: i18n.language === "ar" 
          ? "لقد تجاوزت الحد الأقصى للأقساط لمجموع الطلب. يرجى المحاولة مرة أخرى."
          : "The installment limit for the total order amount has been exceeded. Please try again",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Order Submission Failed",
        text: "Unexpected response from the server. Please try again later.",
      });
    } 
  }finally {
    setLoading(false); // End loading
  }
  
};

useEffect(() => {
  const handlePaymentCompletion = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const Status = urlParams.get('status');
    const message = urlParams.get('message');

    if (Status === 'paid' && message === 'APPROVED' && !hasFetched) {
        setHasFetched(true);
        const userId = localStorage.getItem('userId');
        const cartIds = await getCartIdFromApi();
        const shippingMethodId = urlParams.get('shipping_method_id');
        const addressId = localStorage.getItem('addressId');
        const paymentMethodId = 2; // Assuming 2 is for Apple Pay

        const productsData = cart.map((product) => ({
            product_id: product.product_id,
            quantity: product.quantity,
        }));

        let calculatedTotalPriceMayousar = localStorage.getItem('total');

        const currentYear = new Date().getFullYear();
        const orderNo = `0e-${currentYear.toString().slice(-2)}-${Math.floor(1000 + Math.random() * 9000)}`;

        const data = {
            user_id: userId,
            cart_id: cartIds,
            shipping_method_id: shippingMethodId,
            address_id: addressId,
            payment_method_id: paymentMethodId,
            products: productsData,
            order_no: orderNo,
            total_price: calculatedTotalPriceMayousar,
            status: '1', // Assuming '1' means successful payment
        };

        try {
            const responseOrder = await axios.post(`https://app.regaleyes.sa/api/add-order`, data, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (responseOrder) {
                Swal.fire('Payment Successful', 'Your payment was completed successfully', 'success');
                navigate('/thank-you'); // Navigate to the thank you page
            }
        } catch (error) {
            console.error('Error posting order:', error);
            Swal.fire('Order Submission Failed', 'There was an error processing your order. Please try again later.', 'error');
        }
    } else {
        Swal.fire('Payment Confirmation Failed', message, 'error');
    }
};

  if (cart.length && products && !hasFetched) {
  handlePaymentCompletion();
  }
}, [cart, products, hasFetched]);






useEffect(() => {
  const fetchPaymentStatus = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const paymentId = urlParams.get('payment_id');
    
    if (paymentId && !hasFetched) {  // Only run if not fetched before
      setHasFetched(true);  // Set flag to true to prevent re-running

      try {
        const responseSuccss = await axios.get(`https://app.regaleyes.sa/api/tabby/payment-status/${paymentId}`);
        
        if (responseSuccss?.data?.status === 'AUTHORIZED') {
          console.log("Payment Authorized", responseSuccss.data.status);

          const userId = localStorage.getItem('userId');
          const cartIds = await getCartIdFromApi();
          const shippingMethodId = urlParams.get('shipping_method_id');
          const addressId = localStorage.getItem('addressId');
          const paymentMethodId = 3;

          const productsData = cart.map((product) => ({
            product_id: product.product_id,
            quantity: product.quantity,
          }));

          let calculatedTotalPriceTabby=localStorage.getItem('total'); 

          const currentYear = new Date().getFullYear();
          const orderNo = `0e-${currentYear.toString().slice(-2)}-${Math.floor(1000 + Math.random() * 9000)}`;

          const data = {
            user_id: userId,
            cart_id: cartIds,
            shipping_method_id: shippingMethodId,
            address_id: addressId,
            payment_method_id: paymentMethodId,
            products: productsData,
            order_no: orderNo,
            total_price: calculatedTotalPriceTabby,
            status:'1',
          };

          const responseOrder = await axios.post(`https://app.regaleyes.sa/api/add-order`, data, {
            headers: { Authorization: `Bearer ${token}` },
          });

          if (responseOrder) {
            Swal.fire('Payment Successful', 'Your payment was completed successfully', 'success');
            navigate('/thank-you');
          }
        } else if (responseSuccss?.data?.status === 'REJECTED') {
          Swal.fire({
            icon: "error",
            title: "Order Submission Failed",
            text: "Unexpected response from the server. Please try again later.",
          });
        }
      } catch (error) {
        console.error('Error fetching payment status:', error);
      }
    }
  };

  // Ensure cart and products are loaded before calling fetchPaymentStatus
  if (cart.length && products && !hasFetched) {
    fetchPaymentStatus();
  }
}, [cart, products, hasFetched]);




  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/payment-methods`);
        const paymentMethods = response.data;
        setPaymentMethods(paymentMethods);
      } catch (error) {
        console.error(error);
      }
    };
    fetchPaymentMethods();
  }, []);

  return (
    <div className="container">
           {loading && (
        <div className="loading-overlay">
          <Riple color="#5c5555" size="large" text="Loading" textColor="#ffffff" />
        </div>
      )}
      <div className="fz-checkout">
        <form action="#" className="checkout-form" onSubmit={handleSubmit}>
          <div className="fz-billing-details">
            <div className="row gy-0 gx-3 gx-md-4">
              <h3 className="fz-checkout-title">{t('Billing & Shipping Details')}</h3>
              <div className="col-12 col-xxs-12">
                <Form.Control
                  type="text"
                  placeholder={t('Enter your name')}
                  name="name"
                  value={userData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-12">
                <Form.Control
                  type="email"
                  placeholder={t('Enter your email')}
                  name="email"
                  value={userData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-12">
                <Form.Control
                  type="text"
                  placeholder={t('Enter your phone number')}
                  name="phone"
                  value={userData.phone}
                  onChange={handleChange}
                  required
                />
              </div>





          <div className="col-12 additional-info">
  <label htmlFor="checkout-additional-info" className="fz-checkout-title">{t('Additional Information')}</label>
  <textarea name="notes" id="checkout-additional-info" placeholder={t('Notes about your order, e.g. special notes for delivery.')}></textarea>
</div>
        </div>
      </div>
      <div className="fz-checkout-sidebar">
        <div className="billing-summery">
          <h4 className="fz-checkout-title">{t('Billing & Shipping Summary')}</h4>
          <div className="cart-checkout-area">
          <ul className="checkout-summary">
    <li>
      <span className="checkout-summary__key">{t('Subtotal')}</span>
      <span className="checkout-summary__value"><span>{t(selectedCurrency)} </span> {(subTotal * value).toFixed(2)}</span>
    </li>
    <li>
      <span className="checkout-summary__key">{t('Shipping')}</span>
      <span className="checkout-summary__value"><span>{t(selectedCurrency)}  </span> {(shipping * value).toFixed(2)}</span>
    </li>
    <li>
      <span className="checkout-summary__key">{t('Coupon discount')}</span>
      <span className="checkout-summary__value">-<span>{t(selectedCurrency)}  </span> {calculateDiscountAmount(subTotal * value).toFixed(2)}</span>
    </li>
    <li className="cart-checkout-total">
      <span className="checkout-summary__key">{t('Tax')}</span>
      <span className="checkout-summary__value"><span>{t(selectedCurrency)} </span> {(subTotal * 0.15 * value).toFixed(2)}</span>
    </li>
    <li className="cart-checkout-total">
      <span className="checkout-summary__key">{t('Total')}</span>
      {t(selectedCurrency)}      {((subTotal + +shipping - +calculateDiscountAmount(subTotal)) * value).toFixed(2)}
    </li>
  </ul>


            <Link to="/cart" className="fz-1-banner-btn cart-checkout-btn">{t('Edit Order')}</Link>
            <div className="cart-coupon-form p-2">
  <input type="text" name="code" id="cart-coupon-input" placeholder="Enter Your Coupon Code" />
  <button className="fz-1-banner-btn coupon-apply-btn" onClick={(e) => {
    e.preventDefault();
    const couponCode = document.getElementById('cart-coupon-input').value;
    useCoupon(couponCode);
  }}>{t('Apply Coupon')}</button>
</div>
          </div>
        </div>
        <div className="payment-info">
          <h4 className="fz-checkout-title">{t('Payment Information')}</h4> 

          {/* {paymentMethods.map((method, index) => ( */}
          {paymentMethods.map((method, index) => {
            // Check if the shipping method is 2 and the payment method id is 1
            if (shippingMethodId === '2' && method.id === 1) {
              return null; // Skip rendering this payment method
            }

            return (
              <div key={index}>
                <input
                  type="radio"
                  name="payment-method"
                  value={method.id}
                  checked={selectedPaymentMethod === method.id}
                  onChange={() => setSelectedPaymentMethod(method.id)}
                />
                <span className="p-2">
                  {i18n.language === 'ar' ? method.title_ar : method.title_en}
                </span>
                {method.image && method.image.split(',').map((image, index) => (
                  <img key={index} src={`${apiUrl}/uploads/${image.trim()}`} alt="payment method" width="50" className="fz-payment-methods p-1" />
                ))}
              </div>
            );
          })}


{selectedPaymentMethod === 2 &&(
  <>
    <div class="mysr-form"></div>
  </>
)}


{selectedPaymentMethod === 3 && (
  <>
    <input type="hidden" name="tabby_url" value={`${apiUrl}/tabby-checkout`}/>
    <img src={TabbyBadge} style={{ width: 80, height: 37 }} alt="Tabby Badge" />
    <span style={{ fontSize: 18, fontWeight: 'bolder' }}>
      {i18n.language === 'ar' ? 'قسّمها على 4. بدون أي فوائد، أو رسوم' : 'Pay in 4. No interest, no fees'}
    </span>
    <div className="p-3" id="tabbyCard"></div>
  </>
)}



          <p className="checkout-payment-descr">{t('Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our')} <a href="/privacy-policy">{t('privacy policy')}</a></p>
          <button type="submit" className="fz-1-banner-btn cart-checkout-btn checkout-form-btn">{t('Place Order')}</button>
          
        </div>
      </div>
    </form>
  </div>
</div>
);
};

export default CheckoutSection;
