import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel'; // Import Carousel from react-bootstrap
import { Riple } from "react-loading-indicators";


const apiUrl = import.meta.env.VITE_API_URL;

const BannerSection5 = () => {
  const [banners, setBanners] = useState([]);
  const { t, i18n } = useTranslation(); 
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    axios.get(`${apiUrl}/api/banners`)
      .then(response => {
        console.log(response.data); // Log the response data
        setBanners(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []); // Run once on mount

  // Check if banners are loaded
  if (banners.length === 0) {
    return <div><Riple color="#5c5555" size="large" text="Loading" textColor="#000000" /></div>; // Show loading state
  }

  return (
    <section className="fz-5-banner-section fz-5-banner-slider">
      <Carousel activeIndex={index} onSelect={handleSelect}>
        {banners.map((banner, idx) => {
          console.log(`Banner ${idx}:`, banner); // Log each banner for inspection
          return (
            <Carousel.Item key={idx} style={{ backgroundImage: `url(${apiUrl}/uploads/${banner.image})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '47vh' }}>
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="text-center text-white">
                  <h3>{i18n.language === 'ar' ? banner.title_ar : banner.title_en}</h3>
                  <h1>{i18n.language === 'ar' ? banner.subtitle_ar : banner.subtitle_en}</h1>
                  <br />
                  <Link to={banner.link} className="fz-5-def-btn">
                    {t("shop_now")}
                  </Link>
                </div>
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </section>
  );
};

export default BannerSection5;