import React, { useState, useRef, useContext } from 'react';
import { toast } from 'react-toastify';
import './OtpModal.css'; 
import { useTranslation } from 'react-i18next';


const OtpModal = ({ isOpen, onClose, onSubmit }) => {
    const [otp, setOtp] = useState(['', '', '', '']); // Array to hold each digit of OTP
    const { t } = useTranslation();
    
    const inputRefs = useRef([]); // Ref to hold input elements

    const handleChange = (e, index) => {
        const value = e.target.value;

        // Update the OTP array
        if (/^[0-9]?$/.test(value)) { // Allow only single digit input
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            // Move to the next input if the current input is filled
            if (value && index < 3) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        // Move to the previous input if backspace is pressed
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const otpString = otp.join('');
        if (otpString.length !== 4) {
            toast.error('Please enter a valid 4-digit OTP.', { position: 'top-right' });
            return;
        }
        onSubmit(otpString);
    };

    if (!isOpen) return null;

    return (
        <div className="otp-modal-overlay">
            <div className="otp-modal">
                <h2>{t("Enter OTP")}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="otp-input-container">
                        {otp.map((digit, index) => (
                            <input
                                key={index}
                                type="text"
                                maxLength="1"
                                value={digit}
                                onChange={(e) => handleChange(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                ref={(el) => (inputRefs.current[index] = el)}
                                className="otp-input"
                            />
                        ))}
                    </div>
                    <div className="otp-modal-buttons">
                        <button type="submit" className="btn-verify">{t("Verify OTP")}</button>
                        <button type="button" onClick={onClose}>
                            <i className="bi bi-x-circle" style={{ marginRight: '5px' }}></i> {t("Close")}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default OtpModal;