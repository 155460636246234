import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../authentication/AuthContext';
import GoogleIcon from '../../../public/assets/images/google-icon.png';
import FacebookIcon from '../../../public/assets/images/facebook-icon.png';
import AppleIcon from '../../../public/assets/images/apple-icon.png';
import countries from './countryCodes.json';
import OtpModal from './OtpModal.jsx'; 

const SignInFormSectionPhone = () => {
    const { handleLoginPhone, handleLoginPhoneOtp } = useContext(AuthContext);
    const { t } = useTranslation();
    const [phone, setPhone] = useState('');
    const navigate = useNavigate();
    const [otp, setOtp] = useState(''); // new state to store OTP

    const [countryCode, setCountryCode] = useState('966'); // Default country code
    const [loginUrlGoogle, setLoginUrlGoogle] = useState(null);
    const [loginUrlFacebook, setLoginUrlFacebook] = useState(null);
    const [loginUrlApple, setLoginUrlApple] = useState(null);
    const [otpModalOpen, setOtpModalOpen] = useState(false); // State to control OTP modal
    const [fullPhoneNumber, setFullPhoneNumber] = useState(''); // Store the full phone number

    const apiUrl = import.meta.env.VITE_API_URL;

    useEffect(() => {
        fetch(`${apiUrl}/api/auth/google`)
            .then(response => response.json())
            .then(data => setLoginUrlGoogle(data.url))
            .catch(error => console.error(error));
    }, []);

    useEffect(() => {
        fetch(`${apiUrl}/api/auth/facebook`)
            .then(response => response.json())
            .then(data => setLoginUrlFacebook(data.url))
            .catch(error => console.error(error));
    }, []);

    useEffect(() => {
        fetch(`${apiUrl}/api/auth/apple`)
            .then(response => response.json())
            .then(data => setLoginUrlApple(data.url))
            .catch(error => console.error(error));
    }, []);
    
    const handleFormSubmit = async (e) => {
        e.preventDefault();
    
        if (!phone) {
            toast.error('Please fill out all fields.', { position: 'top-right' });
            return;
        }
    
        // Combine country code and phone number
        const fullPhoneNumber = `${countryCode}${phone}`;
        
        // Send OTP request here
        try {
            await handleLoginPhone(fullPhoneNumber); // Call your API to send OTP
            setFullPhoneNumber(fullPhoneNumber); // Store the full phone number
            setOtpModalOpen(true); // Open OTP modal after sending OTP
        } catch (error) {
            toast.error('Failed to send OTP. Please try again.', { position: 'top-right' });
        }
    };


    const handleOtpSubmit = async (otp) => {
        // Verify the OTP
        try {
            const authenticated = await handleLoginPhoneOtp(fullPhoneNumber, otp);
            if (authenticated) {
                toast.success(t('Signed In successfully!'), { position: 'top-right' });
                setPhone('');
                setOtp(''); // Reset OTP input
                setOtpModalOpen(false); // Close the OTP modal
                navigate('/'); // Redirect after successful verification
            } else {
                toast.error(t('Invalid OTP. Please try again.'), { position: 'top-right' });
            }
        } catch (error) {
            console.error(t('Error during OTP verification:'), error);
            toast.error(t('An error occurred during OTP verification. Please try again.'), { position: 'top-right' });
        }
    };

    return (
        <>
            <form onSubmit={handleFormSubmit}>
                <div className="d-flex align-items-center">
                    <select
                        value={countryCode}
                        onChange={(e) => setCountryCode(e.target.value)}
                        className="form-select me-2 p-2 bg-light"
                        style={{ borderRadius: '10px', width: "130px" }}
                    >
                        {countries.map((country) => (
                            <option key={country.code} value={country.dial_code}>
                                {country.code} ({country.dial_code})
                            </option>
                        ))}
                    </select>
                    <input
                        type="text"
                        name="phone"
                        id="login-phone"
                        placeholder={t('500000000')}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="form-control" // Added class for styling
                        style={{ borderRadius: '10px' }} // Added inline style for consistency
                    />
                </div>
                <div className="sign-in-checkbox-container d-flex justify-content-between">
                    <div className="stay-sign-in">
                        <input type="checkbox" name="sign-in-checkbox" id="sign-in-checkbox" />
                        <label htmlFor="sign-in-checkbox">{t('Stay Logged in')}</label>
                    </div>
                    <Link to="/forget-password" className="password-recovery-btn">{t('Forgot Your Password?')}</Link>
                    <Link to="/sign-up" className="password-recovery-btn">{t('Register')}</Link>
                </div>

                <button type="submit" className="fz-1-banner-btn single-form-btn">{t('Log in')}</button>
            </form>
            <hr />
            <p className='text-center'>{t('Or')}</p>

            <div className="social-icons d-flex justify-content-center m-2">
                <a href={loginUrlGoogle} style={{ marginRight: '10px' }}>
                    <img src={GoogleIcon} alt="Google Icon" style={{
                        backgroundColor: '#e3e3e3',
                        borderRadius: '10%',
                        padding: '10px',
                        cursor: 'pointer',
                        width: 40,
                        height: 40
                    }} />
                </a>

                <a href={loginUrlFacebook} style={{ marginRight: '10px' }}>
                    <img src={FacebookIcon} alt="Facebook Icon" style={{
                        backgroundColor: '#e3e3e3',
                        borderRadius: '10%',
                        padding: '10px',
                        cursor: 'pointer',
                        width: 40,
                        height: 40
                    }} />
                </a>
            </div>

            <OtpModal 
                isOpen={otpModalOpen} 
                onClose={() => setOtpModalOpen(false)} 
                onSubmit={handleOtpSubmit} 
            />
        </>
    );
};

export default SignInFormSectionPhone;