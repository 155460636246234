// import React, { useState, useEffect, useContext } from 'react';
// import { Row, Col } from 'react-bootstrap';
// import { useTranslation } from 'react-i18next';
// import axios from 'axios'; 
// import { Link } from 'react-router-dom';
// import { StoreContext } from '../../context/StoreContext';
// const apiUrl = import.meta.env.VITE_API_URL;
// const ShippingMethodSection = () => {
//   const { cart } = useContext(StoreContext);
//   const [t, i18n] = useTranslation();
//   const [shippingMethods, setShippingMethods] = useState([]);

 
//   useEffect(() => {
//     axios.get(`${apiUrl}/api/shipping-methods`)
//      .then(response => {
//         setShippingMethods(response.data);
//       })
//      .catch(error => {
//         console.error(error);
//       });
//   }, []);

//   return (
//     <div className="container">
//       <div className="fz-checkout">
//         <h4 className='text-center p-2'>{t('Please choose a shipping method!')}</h4>
//         <br />
//         <Row className="justify-content-center">
//           {shippingMethods.slice(0, 2).map((method, index) => (
//             <Col md={4} key={index}>
//               <div className="card" style={{ width: '18rem' }}>
//                 <img className="card-img-top" src={`${apiUrl}/uploads/${method.image}`} alt="Card image cap" />
//                 <div className="card-body">
//                   <h5 className="card-title text-center">{i18n.language === 'en' ? method.title_en : method.title_ar}</h5>
//                   <p className="card-text text-center">{t('SR')} {method.price_shipping}</p>

//                   <Link
//   to={{
//     pathname: '/shipping-address',
//     search: `?shipping_method_id=${method.id}`,
//   }}
//   className="btn btn-dark text-center d-block mx-auto"
// >
//   {t('select')}
// </Link>

//                                  </div>
//               </div>
//             </Col>
//           ))}
//         </Row>
//       </div>
//     </div>
//   );
// };

// export default ShippingMethodSection;
import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios'; 
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import { StoreContext } from '../../context/StoreContext';

const apiUrl = import.meta.env.VITE_API_URL;

const ShippingMethodSection = () => {
  const { cart } = useContext(StoreContext);
  const { t, i18n } = useTranslation();
  const [shippingMethods, setShippingMethods] = useState([]);
  const navigate = useNavigate(); // Use useNavigate hook

  useEffect(() => {
    axios.get(`${apiUrl}/api/shipping-methods`)
      .then(response => {
        setShippingMethods(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const handleShippingMethodSelect = (methodId) => {
    if (methodId == '1') {
      // Redirect to checkout with address_id as NULL
      navigate(`/checkout?address_id=2&shipping_method_id=1`);
    } else {
      // Redirect to shipping address selection
      navigate(`/shipping-address?shipping_method_id=${methodId}`);
    }
  };

  return (
    <div className="container">
      <div className="fz-checkout">
        <h4 className='text-center p-2'>{t('Please choose a shipping method!')}</h4>
        <br />
        <Row className="justify-content-center">
          {shippingMethods.slice(0, 2).map((method, index) => (
            <Col xs={6} sm={6} md={4} className="d-flex justify-content-center mb-4" key={index}>
              <div className="card" style={{ width: '15rem' }}>
                <img className="card-img-top" src={`${apiUrl}/uploads/${method.image}`} alt="Card image cap" />
                <div className="card-body">
                  <h5 className="card-title text-center">{i18n.language === 'ar' ? method.title_ar : method.title_en}</h5>
                  <p className="card-text text-center">{t('SR')} {method.price_shipping}</p>

                  <button
                    className="btn btn-dark text-center d-block mx-auto"
                    onClick={() => handleShippingMethodSelect(method.id)}
                  >
                    {t('select')}
                  </button>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default ShippingMethodSection;
