import React, { useState, useEffect } from 'react';
import BreadcrumbSection from '../breadcrumb/BreadcrumbSection';
import ProductDetailSection from '../product/ProductDetailSection';
import RelatedProductSection from '../product/RelatedProductSection';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet'; 
import axios from 'axios';
import { Riple } from "react-loading-indicators";


const apiUrl = import.meta.env.VITE_API_URL;

const ShopDetailsMain = () => {
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug_en } = useParams();
  const { i18n } = useTranslation();
  
  // SEO Start
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [metaImage, setMetaImage] = useState('');
  
  const keywords = {
    en: "sunglasses, eyeglasses, contact lenses, eyewear chains, Cartier eyewear, Mont Blanc glasses, Maybach eyewear, Lotos glasses, Lucas de Stael eyewear, Freddie Wood eyewear, Dita sunglasses, Leisure Society eyewear, IC Berlin glasses",
    ar: "نظارات شمسية, نظارات طبية, عدسات لاصقة, سلاسل النظارات, نظارات كارتييه, نظارات مونت بلانك, نظارات مايباخ, نظارات لوتس, نظارات لوكاس دو ستيل, نظارات فريدي وود, نظارات ديتا, نظارات ليجر سوسايتي, نظارات آي سي برلين"
  };
  const titles = {
    en: "Regalesyes | ",
    ar: "ريجل ايز | "
  };  

  const currentKeywords = i18n.language === 'ar' ? keywords.ar : keywords.en;
  const currentTitles = i18n.language === 'ar' ? titles.ar : titles.en;
  // SEO End
  
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/product/${slug_en}`);
        const data = response.data;
        setProduct(data);
        setMetaTitle(i18n.language === 'ar' ? data.meta_title_ar : data.meta_title_en);
        setMetaDescription(i18n.language === 'ar' ? data.meta_description_ar : data.meta_description_en);
        setMetaImage(data.meta_img ? `${apiUrl}/uploads/${data.meta_img}` : ''); // Constructing the full image URL
        setLoading(false);
      } catch (error) {
        console.error(error);
        setError('Failed to load product details');
        setLoading(false);
      }
    };

    fetchProduct();
  }, [slug_en, i18n.language]);

  if (loading) return <div><Riple color="#5c5555" size="large" text="Loading" textColor="#000000" /></div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <Helmet>
        <title>{currentTitles} {metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={currentKeywords} />
        <meta property="og:image" content={metaImage} />
      </Helmet>
      <BreadcrumbSection 
        title={product.title_en && i18n.language === 'ar' ? product.title_ar : product.title_en || "Shop Details"} 
        current={product.title_en && i18n.language === 'ar' ? product.title_ar : product.title_en}
      />
      <ProductDetailSection product={product}/> 
      {/* <RelatedProductSection brandId={product.brand_id} />  */}
    </>
  );
}

export default ShopDetailsMain;