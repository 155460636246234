import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom"; // Import NavLink instead of Link
import MobileMenuSidebar from "./MobileMenuSidebar";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../authentication/AuthContext";

const BottomMobileMenu = ({ style, logo }) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useContext(AuthContext); 
  const [activeSidebar, setActiveSidebar] = useState(false);

  const openMenu = () => {
    setActiveSidebar(true);
  };

  const closeMenu = () => {
    setActiveSidebar(false);
  };

  return (
    <div className={`fz-4-mobile-menu ${style} d-lg-none`}>
      <ul className="fz-3-app-menu">
      <li>
          <NavLink to="/shop" activeClassName="active">
            <span className="icon">
              <i className="fa-light fa-shop"></i>
            </span>
            <span className="txt">{t('Shop')}</span>
          </NavLink>
        </li>

        {isLoggedIn && (
          <li>
            <NavLink to="/account" activeClassName="active">
              <span className="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
  <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
</svg>
              </span>
              <span className="txt">{t('Account')}</span>
            </NavLink>
          </li>
        )}

        <li>
          <NavLink to="/" activeClassName="active" exact>
            <span className="icon">
              <i className="fa-light fa-home"></i>
            </span>
            <span className="txt">{t('Home')}</span>
          </NavLink>
        </li>

        {isLoggedIn && (
          <li>
            <NavLink to="/cart" activeClassName="active">
              <span className="icon">
              <i className="fa-light fa-cart-shopping"></i>
              </span>
              <span className="txt">{t('Cart')}</span>
              </NavLink>
          </li>
        )}
        <li>
          <a role="button" className="open-main-menu" onClick={openMenu}>
            <span className="icon">
              <i className="fa-light fa-grid-2"></i>
            </span>
            <span className="txt">{t('Menu')}</span>
          </a>
        </li>
      </ul>
      <div
        className={`app-menu-overlay ${activeSidebar ? "active" : ""}`}
        role="button"
        onClick={closeMenu}
      ></div>
      <div className={`main-menu ${activeSidebar ? "active" : ""}`}>
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <MobileMenuSidebar /> 
      </div>
    </div>
  );
};

export default BottomMobileMenu;