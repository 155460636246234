import React, { useContext, useState, useEffect } from 'react';
import { FarzaaContext } from '../../context/FarzaaContext';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../context/StoreContext';
import { CurrencyContext } from '../../context/CurrencyContext';  //Currency 
import { AuthContext } from '../authentication/AuthContext'; 
import axios from 'axios';
import { Riple } from "react-loading-indicators";


const apiUrl = import.meta.env.VITE_API_URL;

const ProductContainer2 = ({ shapeId, genderId, brandId }) => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();
  const { token } = useContext(AuthContext); // Get token from AuthContext
  const { selectedCurrency, value } = useContext(CurrencyContext); //Currency 
  const navigate = useNavigate(); // Initialize navigate
  const [products, setCategoryProducts] = useState([]);
  const [pagination, setPagination] = useState({});
  const { isListView } = useContext(FarzaaContext);
  const { addToWishlist, addToCart } = useContext(StoreContext);

  useEffect(() => {
    fetchProducts(); // Fetch products on initial load
  }, [slug]); // Fetch when slug changes

  const handleAddToCart = (product) => {
    if (!token) {
      // If user is not logged in, redirect to login
      navigate('/login');
    } else {
      // If user is logged in, add to cart
      addToCart(product);
    }
  };

  const fetchProducts = async (pageNumber = 1) => {
    try {

      let url = `${apiUrl}/api${pathname}?page=${pageNumber}`; // Create a new variable for the URL
      // Modify the URL based on the filters
      const params = new URLSearchParams();
  
      if (shapeId) {
        params.append('shape_id', shapeId);
      }
      if (genderId) {
        params.append('gender_id', genderId);
      }
      if (brandId) {
        params.append('brand_id', brandId);
      }
  
      // Append the filters to the URL
      if (params.toString()) {
        url += `&${params.toString()}`;
      }
  
      const response = await axios.get(url);
      const { data, ...paginationData } = response.data;
      setCategoryProducts(data);
      setPagination(paginationData);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false); // End loading
    }
  };
  if (loading) {
    return <Riple color="#5c5555" size="large" text="Loading" textColor="#000000" />;
  }
  

  return (
    <div className={`fz-inner-products-container ${isListView ? 'list-view-on' : ''}`}>
      <div className="row justify-content-center">
        {products.length === 0 ? (
          <div className='no-product-area'>
            <h3 className='no-product-text'>{t('No Products Available')}</h3>
            <p className='no-product-desc'>{t("We're sorry. We cannot find any matches for your search term.")}</p>
          </div>
        ) : (
          products.map((product) => (
            <div className="col-lg-4 col-md-4 col-6 order-lg-1 order-2" key={product.id}>
              <div className="fz-5-single-product">
                <div className="fz-5-single-product-img">
                  <img src={`${apiUrl}/uploads/${product.image}`} alt={product.title_en} />
                  <div className="fz-5-single-product-actions">
                    <a
                      role="button"
                      className="fz-add-to-wishlist"
                      onClick={() => addToWishlist(product)}
                    >
                      <i className="fa-regular fa-heart"></i>
                    </a>
                    <a
                      role="button"
                      className="fz-add-to-cart-btn"
                      onClick={() => handleAddToCart(product)}
                    >
                      <i className="fa-regular fa-cart-shopping"></i>
                    </a>
                  </div>
                </div>
                <div className="fz-5-single-product-txt">
                  <h3 className="fz-5-single-product-title">
                    <Link to={`/product/${product.slug_en}`}>
                      {i18n.language === 'ar' ? product.title_ar : product.title_en}
                    </Link>
                  </h3>
                  <p className="fz-5-single-product-price">{t(selectedCurrency)} {(product.selling_price * value).toFixed(2)}</p>


                </div>
              </div>
            </div>
          ))
        )}
      </div>
      {/* Pagination */}
      <div className="p-2">
        {pagination.links && (
          <nav aria-label="Pagination">
            <ul className="pagination justify-content-center flex-wrap">
              {pagination.links.map((link, index) => (
                <li key={index} className={`page-item ${link.active ? 'active' : ''}`}>
                  <a
                    className="page-link"
                    href={link.url}
                    onClick={(e) => {
                      e.preventDefault();
                      const pageNumber = link.label === 'Next' ? pagination.current_page + 1 : link.label === 'Previous' ? pagination.current_page - 1 : link.label;
                      fetchProducts(pageNumber);
                    }}
                    style={{
                      color: 'black',
                      fontSize: '0.9rem', // Adjust font size for mobile
                    }}
                  >
                    {link.label === '&laquo; Previous' ? t('Previous') : link.label === 'Next &raquo;' ? t('Next') : link.label}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
};

export default ProductContainer2;