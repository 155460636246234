import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { StoreContext } from '../../context/StoreContext'; 
import { AuthContext } from '../authentication/AuthContext'; 
import { CurrencyContext } from '../../context/CurrencyContext';  //Currency 


const apiUrl = import.meta.env.VITE_API_URL;




const AccesoriesSection = () => {
  const { addToWishlist, addToCart } = useContext(StoreContext);
  const {token} = useContext(AuthContext);
  const navigate = useNavigate(); // Initialize navigate
  const { selectedCurrency, value } = useContext(CurrencyContext); //Currency 

  const { t, i18n } = useTranslation();
  const { slug } = useParams();
  const [products, setCategoryProducts] = useState([]);

  const handleAddToCart = (product) => { 
    if (!token) {
        // If user is not logged in, redirect to login
        navigate('/login');
    } else {
        // If user is logged in, add to cart
        addToCart(product);
    }
};
const handleAddToWishlist = (product) => {
  if (!token) {
      // If user is not logged in, redirect to login
      navigate('/login');
  } else {
      // If user is logged in, add to wishlist
      addToWishlist(product);
  }
};


  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const url = `${apiUrl}/api/products/category_3/accessories`;
        const response = await axios.get(url);
        const data = response.data.data; // Access the data property
  
        // Use slice to get only the last 3 products
        const lastThreeProducts = data.slice(-3);
        setCategoryProducts(lastThreeProducts); // Set the last 3 products
      } catch (error) {
        console.error(error);
      }
    };
    fetchProduct();
  }, [slug]);


  return (
    <section className="fz-5-product pt-120 pb-120">
      <div className="container">
        <div className="row g-xl-4 g-lg-3 g-4">
          <div className="col-12">
            <div className="row g-xl-4 g-lg-3 g-2">
              {/* /////start loop products////// */}
              {products.map((product) => (
              <div className="col-lg-3 col-md-4 col-6 order-lg-1 order-2">
                <div className="fz-5-single-product">
                  <div className="fz-5-single-product-img">
                    <img src={`${apiUrl}/uploads/${product.image}`} alt={product.title_en} />
                    <div className="fz-5-single-product-actions">
                      <a 
                      role="button" 
                      className="fz-add-to-wishlist"
                      onClick={() => handleAddToWishlist(product)}
                      >
                        <i className="fa-regular fa-heart"></i>
                      </a>
                      {/* <a role="button" className="fz-quick-view">
                        <i className="fa-regular fa-eye"></i>
                      </a> */}
                      {/* <a role="button" className="fz-compare">
                        <i className="fa-regular fa-repeat"></i>
                      </a> */}
                      <a
                       role="button"
                       className="fz-add-to-cart-btn" 
                       onClick={() => handleAddToCart(product)}
                       >
                        <i className="fa-regular fa-cart-shopping"></i>
                      </a>
                    </div>
                  </div>
                  <div className="fz-5-single-product-txt">
                    <h3 className="fz-5-single-product-title">
                      <Link to={`/product/${product.slug_en}`}>{i18n.language === 'ar' ? product.title_ar : product.title_en}</Link>
                    </h3>
                    <p>{t(selectedCurrency)} {(product.selling_price * value).toFixed(2)}</p>
                    </div>
                </div>
              </div>
              ))}

{/* /////end loop products////// */}

              <div className="col-lg-3 col-md-4 col-6 order-lg-4 order-1">
                <div className="fz-5-product-title-box">
                  <h3>{t('Accesories')}</h3>
                  <Link to="/products/category/accessories" className="fz-5-def-btn-2">
                    {t('View Products')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccesoriesSection;
