import React from 'react';
import Layout from '../components/layout/Layout';
import ShopMain from '../components/main/ShopMain';

const Shop = ({ categoryId, shapeId, genderId, brandId }) => {

  return (
    <Layout>
      <ShopMain
      />
    </Layout>
  );
};

export default Shop;
